<template>
  <div id="map">
  </div>
</template>
<script>
export default {
  data() {
    return {
      amap: null,
      infoWindow: null
    }
  },
  props:{
    list: {
      default: () =>[],
      type: Array
    },
    select_item: {
      default:null,
      type: Object
    }
  },
  watch: {

  },
  created() {

  },
  mounted() {
    this.init_map()
  },
  methods: {
    init_map() {
      this.amap = new AMap.Map("map", {
        zoom: 11,
        center: [this.select_item.location.longitude,this.select_item.location.latitude],
        resizeEnable: true
      })
      this.infoWindow = new AMap.InfoWindow({
        anchor: 'middle-right',
      })
      this.amap.clearMap()
      this.list.forEach(i=>{
        this.mark_map(i,[i.location.longitude,i.location.latitude])
      })

    },
    mark_map(i,location){
      let that = this
      var markerContent =
      `<div class="custom-content-marker">
        <img height="30" width="16" src="./img/order/mark.png">
      </div>`
      var marker = new AMap.Marker({
        position: location,
        content: markerContent,
        offset: new AMap.Pixel(-10, -10)
      })
      marker.setMap(that.amap)
      if(that.select_item&&that.select_item.standard_id === i.standard_id) {
        that.infoWindow.setContent(that.select_item.name)
        that.infoWindow.open(that.amap, marker.getPosition())
      }
      marker.on('click', function(e) {
        that.infoWindow.setContent(i.name)
        that.infoWindow.open(that.amap, e.target.getPosition())
        that.$emit('select_location',i)
      })

    }
  },
}
</script>
<style scoped>
#map {
  width: 100%;
  height: 100%;
}
>>>.amap-logo {
  display: none !important;
}
>>>.amap-mcode  {
  display: none !important;
}
>>>.amap-copyright {
  display: none !important;
}
>>>.loaction-label {

}

</style>


