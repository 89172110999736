<template>
  <van-overlay :lock-scroll="false" :show="visible">
    <g-loading :loading="loading" />
    <div class="loaction">
      <Map class="map" :select_item="select_item" @select_location="select_location" v-if="organizations.length>0" :list="organizations"/>
      <div class="bottom-content" v-if="select_item">
        <div class="name">{{select_item.name}}</div>
        <div>{{select_item.address}}</div>
        <div>营业时间: {{select_item.business_hours.text}}</div>
        <div class="comfirm" @click="comfirm">确认</div>
      </div>
    </div>
  </van-overlay>
</template>
<script>
import {get_order} from './service'
import Map from './Map'
import { isEmptyObj } from '../../utils/validate'
export default {
  data() {
    return {
      loading: false,
      organizations: [],
      select_item: null
    }
  },
  props: {
    visible: {
      default:false,
      type: Boolean
    },
    location:{
      default:()=>{},
      type: Object
    },
    order_group_id: {
      default:'',
      type: [String,Number]
    },
    id: {
      default:'',
      type: [String,Number]
    }
  },
  components: {
    Map
  },
  computed:{
  },
  created() {
    this.search_order()
  },
  methods: {
    select_location(i) {
      this.select_item = i
    },
    comfirm() {
      let {select_item} = this
      this.$emit('set_location',select_item)
      this.$emit('update:visible',false)
    },
    async search_order () {
      try {
        const {order_group_id,location} = this.$route.query
        let params = {
          order_group_id,
          ...location
        }
        this.loading = true
        const {data} = await get_order(params)
        for(let i of data) {
          if(this.id === i.standard_id) {
            this.select_item = i
          }
        }
        if(isEmptyObj(this.select_item)){
          this.select_item = data[0]
        }
        this.organizations = data
      } finally  {
        this.loading = false
      }
    },
  }
}
</script>
<style scoped>
.loaction {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #FFFFFF;
}
.bottom-content {
  position: absolute;
  z-index: 10;
  background: #FFFFFF;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  bottom:0;
  left: 0;
  right: 0;
  padding: 21px 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: #565656;
  & .comfirm {
    height: 40px;
    background: #0088FF;
    border-radius: 9px;
    font-size: 16px;
    font-weight: 500;
    line-height: 7px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 17px;
  }
  & .name {
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    color: #565656;
    padding-bottom: 7px;
  }
}
</style>
