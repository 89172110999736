import api from '../../utils/api'
// 登录
export async function get_order (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: [{
  //         standard_id: 'HOS_0007',
  //         location: {
  //           latitude: 22.546318,
  //           longitude: 114.133396
  //         },
  //         name: '罗湖医院检验点',
  //         business_hours: {
  //           text: '08:00 至 24:00'
  //         },
  //         address: '深圳市罗湖区罗湖路 2楼检验中心1'

  //       },{
  //         standard_id: 'HOS_0002',
  //         location: {
  //           latitude: 22.601638,
  //           longitude: 114.120628
  //         },
  //         name: '罗湖医院检验点',
  //         business_hours: {
  //           text: '08:00 至 24:00'
  //         },
  //         address: '深圳市罗湖区罗湖路 2楼检验中心2'
  //       }]
  //     })
  //   }, 500)
  // })
  return api.get('/api/v1/mp/list/hospital', {params:payload})
}
