import api from '../../utils/api'

// 登录
export async function info (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         name: '张三',
  //         token: 'eyJ1c2VyX2lkIjoxLCJleHBpcmVfdGltZSI6MTYxMTY1MDc5ODUxMn0.sOU12Rv6lTloal_pTTp_oWJ_QpE'
  //       }
  //     })
  //   }, 500)
  // })
  return api.get('/api/v1/mp/inquiries/session/labAndExame/info', {params: payload})
}

// 退出
export async function confirm_order (payload) {
  return api.post(`/api/v1/mp/orders/group`, payload)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}

export async function hospital (payload) {
  return api.post(`/api/v1/mp/address/organization/nearest`, payload)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}

export async function pay_order (payload) {
  return api.get(`/api/v1/mp/order_groups/${payload}/pay`)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/group`,payload)
}
