<template>
  <div class="g_page_box">
    <div class="g_main_content">
      <g-loading :loading="loading" />
      <div class="head flex">
        <div>
          <div class="head_title">
            {{ !invalid ? "确认订单" : data.order_group_id? "订单已支付":"订单已失效" }}
          </div>
          <div class="head_sub">
            {{
              !invalid
                ? "请核实您的检查检验订单"
                : "如您需再次购买需重新联系医生"
            }}
          </div>
          <div class="head_sub" v-if="!invalid">
            倒计时：<van-count-down :time="time" @finish="count_change" />
          </div>
        </div>
        <div class="head_right">
          <img src="../../assets/img/order-icon.png" alt="" width="18" />
          <p>就诊人：{{ data.patient_name }}</p>
        </div>
      </div>
      <div class="center">
        <div class="cards">
          <div class="cards_head">
            <div class="cards_head-title">检查清单</div>
          </div>
          <div class="cards_center">
            <div class="cards_item flex bordered" @click="select_position()">
              <div class="cards_left">
                <div class="cards_title">检查点</div>
              </div>
              <div class="cards_right">
                <div class="cards_title">
                  {{hospital.name}}
                </div>
                <van-icon name="arrow" />
              </div>
            </div>
            <div class="cards_item flex">
              <div class="cards_left">
                <div class="cards_title">检查时间</div>
              </div>
              <div class="cards_right">
                <div @click="show = true" class="cards_title">
                  {{ format_date(date, "yyyy年MM月dd日") }}
                </div>
                <van-icon name="arrow" />
              </div>
            </div>
          </div>
        </div>

        <div class="cards">
          <div class="cards_head">
            <div class="cards_head-title">检查清单</div>
          </div>
          <div class="bordered">
            <div class="cards_item flex s2" v-if="data.lab&&data.lab.length">
              <img src="../../assets/img/ai_doc-ill.png" alt="" width="16" />
              <div class="cards_center">
                <p>检验</p>
                <div class="cards_center-inner">
                  <span v-for="item in data.lab" :key="item.id">{{
                    item.name
                  }}</span>
                </div>
              </div>
              <div class="cards_sub">¥{{ format_money(data.lab_price) }}</div>
            </div>
            <div class="cards_item flex s2" v-if="data.exam && data.exam.length">
              <img src="../../assets/img/ai_doc-ill.png" alt="" width="16" />
              <div class="cards_center">
                <p>检查</p>
                <div class="cards_center-inner">
                  <span v-for="item in data.exam" :key="item.id">{{
                    item.name
                  }}</span>
                </div>
              </div>
              <div class="cards_sub">¥{{ format_money(data.exam_price) }}</div>
            </div>
          </div>
          <div class="cards_bottom">
            <p>小计： ¥{{ format_money(data.lab_price + data.exam_price) }}</p>
          </div>
        </div>

        <div class="cards">
          <div class="cards_head-title">处方信息</div>
          <div class="cards_box">
            <div class="cards_bottom-item flex">
              <div class="cards_title">申请医生</div>
              <div class="cards_title">{{ data.online_doctor_name }}</div>
            </div>
            <div class="cards_bottom-item flex">
              <div class="cards_title">审方医生</div>
              <div class="cards_title">{{ data.audit_doctor_name }}</div>
            </div>
            <div class="cards_bottom-item flex">
              <div class="cards_title">申请时间</div>
              <div class="cards_sub">
                {{ format_date(data.apply_time, 'yyyy-MM-dd HH:mm:ss') }}
              </div>
            </div>
            <div class="cards_bottom-item flex">
              <div class="cards_title">审核时间</div>
              <div class="cards_sub">
                {{ format_date(data.audit_time, 'yyyy-MM-dd HH:mm:ss') }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom flex">
        <div class="left">
          <div class="price">
            ¥{{ format_money(data.lab_price + data.exam_price) }}
          </div>
          <div class="bottom_sub">总金额</div>
        </div>
        <div class="right">
          <div class="bottom_btn" v-if="!invalid" @click="btn_click">自费支付</div>
          <div class="bottom_btn" v-if="!invalid" @click="btn_click">医保支付</div>
          <div class="bottom_btn invalid" v-if="invalid" >{{data.order_group_id?'已支付':'已失效'}}</div>
        </div>
      </div>
      <van-calendar
        v-model="show"
        @confirm="onConfirm"
        :show-confirm="false"
        color="#0088FF"
      />
    </div>
    <MonitoringLocation
      @set_location="set_location"
      :visible.sync="visible"
      v-if="visible"
      :location="location"
      :id="location_id"
      :order_group_id="id"
    />
  </div>
</template>

<script>
import { info, confirm_order, hospital, pay_order } from "./service";
import { format_date, format_money } from "../../utils/format";
import MonitoringLocation from "../../components/monitoring-location";
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      time: 1000*60,
      invalid: false,
      loading: false,
      id: 2,
      order_id: null,
      data: {},
      show: false,
      date: new Date(),
      visible: false,
      hospital: {
        name:'',
        standard_id: ''
      },
      location: {
        name:'',
        standard_id: ''
      },
      location_id: '',
    };
  },
  computed: {
    ...mapState("commonData", {
      geolocation: (state) => state.geolocation,
    }),
  },
  components: {
    MonitoringLocation,
  },
  watch: {
    geolocation() {
    },

  },
  created() {
    this.order_id = this.$route.query.order_id;
    this.id = this.$route.query.id;
    this.get_info();
    this.get_hospital()

    window.history.pushState(null, null)
    window.addEventListener("popstate", this.popstate, false)
  },
  destroyed() {
    window.removeEventListener("popstate", this.popstate, false)
  },
  methods: {
    count_change(){
      this.invalid = true
    },
    async get_hospital() {
      this.loading = true
      try {
        const {data} = await hospital({
          longitude:this.geolocation.lng,
          latitude: this.geolocation.lat
        })
        if(data){
          this.hospital.name = data.name
          this.hospital.standard_id = data.standard_id
        }
      } finally {
        this.loading = false
      }
    },
    ...mapActions("commonData", ["get_geolocation"]),
    format_date,
    format_money,
    async get_info() {
      this.loading = true;
      try {
        const { data } = await info({ id: this.order_id });
        data.lab = [];
        data.exam = [];
        data.lab_price = 0;
        data.exam_price = 0;
        data.lab_self_price = 0
        data.lab_ill_price = 0
        data.exam_self_price = 0
        data.exam_ill_price = 0
        data.lab_exames.forEach((el) => {
          if (el.code === 1) {
            data.lab.push(el);
            if(el.type===1){
              data.lab_self_price += el.price
            } else {
              data.lab_ill_price += el.price
            }
          } else if (el.code === 2) {
            data.exam.push(el);
            if(el.type===1){
              data.exam_self_price += el.price
            } else {
              data.exam_ill_price += el.price
            }
          }
        });
        data.lab_price = data.lab_self_price+data.lab_ill_price
        data.exam_price = data.exam_self_price + data.exam_ill_price
        this.data = data;
        if(data.order_group_id || data.expire_time < data.current_time){
          this.invalid = true
        } else {
          this.time = data.expire_time - data.current_time
        }

      } finally {
        this.loading = false;
      }
    },
    popstate(e) {
      //qiankun 微服务内部api，更新可能引起未知bug
      if(e.singleSpaTrigger === "pushState" || e.singleSpaTrigger === "replaceState"){
        return
      }
      if (this.visible) {
        this.visible = false;
        window.history.pushState(null, null)
      } else {
        history.go(-1)
      }
    },
    async btn_click() {

      let order = []
      let obj1 = this.data.lab.length
        ? {
          order_type:1,
          reservation_day: new Date(this.date).getTime(),
          items: [...this.data.lab]
        }:''

      if(this.data.exam.length === 1){
        order.push({
          order_type: 2,
          shop_id: this.hospital.standard_id,
          reservation_day: new Date(this.date).getTime(),
          items: [...this.data.exam],
        })
      } else if(this.data.exam.length > 1) {
        this.data.exam.forEach((item) => {
          order.push({
            order_type: 2,
            shop_id: this.hospital.standard_id,
            reservation_day: new Date(this.date).getTime(),
            items: [item],
          });
        });
      }

      if (obj1) order.push(obj1);

      const prama = {
        orders: [...order],
        pay_by_self_amount: this.data.lab_self_price+this.data.exam_self_price,
        pay_by_medical_insurance: this.data.lab_ill_price+this.data.exam_ill_price,
        pay_third_part_amount: 0,
        total_amount: this.data.lab_price + this.data.exam_price,
        drug_program_id: this.order_id
      }
      this.loading = true;
      try {
        const { data } = await confirm_order(prama);
        //this.clearState();
        //this.$router.push({ path: "/pay", query: { id: data.order_group_id } });
        await pay_order(data.order_group_id)
        this.$notify({ type: "success", message: "支付成功" })
        let that = this
        setTimeout(()=>{
          that.$router.push({
            path: '/online-chat',
            query: {id: this.id}
          })
        },500)
      } finally {
        this.loading = false;
      }
    },
    onConfirm(date) {
      this.show = false;
      this.date = date;
    },
    select_position() {
      this.location_id = this.hospital.standard_id;
      const prama = {
        order_group_id: this.location_id,
        ...this.geolocation,
        id: this.order_id
      };
      this.$router.push({ path: "/inspection-order", query: prama });
      this.visible = true;
    },
    set_location(location) {
      // 例子
      this.$router.push({ path: "/inspection-order", query: {id: this.id}});
      this.hospital.name = location.name;
      this.hospital.standard_id = location.standard_id;
      this.visible = false;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.g_page_box {
  background: #f2f7f8;
  padding-bottom: 60px;
}

.head {
  padding: 15px 16px;
  background: url("../../assets/img/family-bg.png") right center no-repeat
    #000ca8;
  background-size: contain;
  color: #ffffff;
  height: 114px;
}

.head_title {
  font-size: 18px;
  line-height: 25px;
}

.head_sub {
  font-size: 12px;
  line-height: 17px;
  display: flex;
  align-items: center;
}

>>> .van-count-down {
  color: #fff;
  font-size: 12px;
  line-height: 17px;
}

.center {
  margin: -16px 16px 0;
  position: relative;
}

.cards {
  padding: 14px 10px;
  background: #ffffff;
  border-radius: 8px;
  margin-bottom: 14px;
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.cards_title {
  font-size: 14px;
  line-height: 20px;
  color: #292929;
}

.cards_sub {
  font-size: 12px;
  line-height: 20px;
  color: #919191;
}

.cards_head {
  padding: 3px 0;
}

.cards_head-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #3d3d3d;
}

.cards_item {
  padding: 7px 0;

  &.bordered {
    border-bottom: solid 1px rgba(112, 112, 112, 0.09);
  }

  &.s2 {
    align-items: flex-start;
  }
}

.cards_bottom {
  padding: 18px 0 6px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #292929;
  display: flex;
  justify-content: flex-end;
}

.bordered {
  border-bottom: solid 1px rgba(112, 112, 112, 0.09);
}

.cards_bottom-item {
  margin-bottom: 5px;

  & .cards_title {
    font-weight: 400;
  }
}

.cards_sub-bottom {
  padding-top: 14px;
  justify-content: flex-end;
}

.cards_box {
  padding-top: 10px;
}

.bottom {
  position: fixed;
  width: 100%;
  height: 80px;
  bottom: 0;
  background: #fff;
  padding: 10px 10px 0 20px;
}

.right {
  display: flex;
  align-items: center;
}

.price {
  font-size: 22px;
  font-weight: 600;
  color: #ff6600;
}

.bottom_sub {
  font-size: 12px;
  line-height: 17px;
  color: #707070;
}

.bottom_btn {
  height: 36px;
  background: #1890ff;
  border-radius: 18px;
  line-height: 36px;
  padding: 0 17px;
  margin-left: 7px;
  font-size: 14px;
  color: #ffffff;
  text-align: center;

  &.invalid {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(255,255,255,.6);
    }
  }
}

.head_right {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 32px;
  color: #ffffff;

  & img {
    margin-right: 5px;
  }
}

.cards_right {
  display: flex;
  align-items: center;
}

.cards_center {
  flex: 1;
  margin: 0 10px;
  font-size: 16px;
  font-weight: 500;
  color: #565656;
  padding-bottom: 0;
}

.cards_center-inner {
  display: flex;
  align-items: center;
  padding-top: 6px;
  flex-wrap: wrap;

  & span {
    font-size: 12px;
    line-height: 18px;
    color: #0088ff;
    margin-right: 3px;
  }
}
</style>
